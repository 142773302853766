<template>
  <v-app id="inspire" v-if="showApp">
    <v-app-bar
      v-if="auth"
      app
      dense
      dark
      class="indigo darken-1 justify-space-between"
    >
      <v-toolbar-title>Файлы</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn dark outlined class="me-2" @click="logout"><v-icon>mdi-exit-to-app</v-icon></v-btn>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import api from "./core/axios";

export default {
  data: () => {
    return {
      drawer: null,
      showApp: false,
    };
  },

  computed: {
    auth: function () {
      return !!this.$root.user;
    },

  },

  methods: {
    logout:async function() {
      await api.post('auth/logout')
      this.$root.user = null
      window.localStorage.removeItem('token')
      this.$router.push({name: 'login'})
    }
  },
  created: async function () {
    let token = window.localStorage.getItem("token");
    if (!token) {
      if (this.$route.name !== "login") {
        this.$router.push({ name: "login" });
      }
      this.showApp = true;
      return;
    }
    api.defaults.headers.common["Authorization"] = "Bearer " + token;
    try {
      let response = await api.get("auth/user");
      this.$root.user = response.data.user;
      if (this.$root.user.locale) {
        this.$root.$i18n.locale = this.$root.user.locale;
      }

      if (this.$route.name == "login") {
        this.$router.push({ name: "dashboard" });
        this.showApp = true;
        return;
      }
    } catch (error) {
      delete api.defaults.headers.common["Authorization"];
      window.localStorage.removeItem("token");
      if (this.$route.name !== "login") {
        this.$router.push({ name: "login" });
        return;
      }
      return;
    } finally {
      this.showApp = true;
    }
  },
};
</script>
