const login = () => import('./../../views/auth/login.vue')
const files = () => import('./../../views/files.vue')

const routes = [

    {
        path: '/',
        name: 'files',
        component: files,
    },

    {
        path: '/login',
        name: 'login',
        component: login,
    },
]

export default routes