import Vue from 'vue'
import router from './core/router'
import vuetify from './core/vuetify'

import app from './App.vue'

Vue.config.productionTip = false

const vue = new Vue({
  router,
  vuetify,
  data: () => {
    return {
      user: null,
    }
  },
  render: function (h) { return h(app) }
}).$mount('#app')

export default vue