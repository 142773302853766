import axios from "axios";
import vue from './../../main.js'

const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: false,
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json" 
    }
  });

  api.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      switch(error.response.status) {
        case 401:
        case 403:
          vue.user = null
          window.localStorage.removeItem('token')
          if(vue.$router.currentRoute.name != 'login'){
            vue.$router.push({ name: 'login' });
          }
          break
        case 404:
          break
        case 419:
          window.location.reload()
        break
      }
      return Promise.reject(error);
    }
  )

  export default api